import Utils from '../../lib/utils';
import * as types from './ciam.types';

// initialState
const initialState = Utils.getStateTemplate('ciam');

// Reducer
function ciamReducer(state = initialState, action = {}) {
  // Currently just a basic pass through reducer as none of our actions alter state
  switch (action.type) {
    case types.CONNECT_APPLICATION_FAILURE:
      return {
        ...state,
        applicationConnectionFailed: true,
      };
    case types.CONNECT_APPLICATION_ABSENT_PARTY:
      return {
        ...state,
        applicationConnectionAbsentParty: true,
      };
    case types.RESET_CONNECT_APPLICATION_ERROR:
      return {
        ...state,
        applicationConnectionFailed: false,
        applicationConnectionAbsentParty: false,
      };
    case types.FETCH_PROFILE_DATA:
      return {
        ...state,
        isFetching: true,
      };
    case types.FETCH_PROFILE_DATA_SUCCESS:
      return {
        ...state,
        isFetching: false,
        profile: action.profile,
      };
    case types.FETCH_PROFILE_DATA_FAILURE:
      return {
        ...state,
        isFetching: false,
      };
    default:
      return state;
  }
}

export default ciamReducer;
