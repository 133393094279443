import styled from 'styled-components';
import { breakpoint } from '@aib/design-tokens';

export const ContactContainer = styled.span`
  font-weight: bold;
  white-space: nowrap;
`;

export const Paragraph = styled.div`
  margin: 1rem 0;
`;

export const Title = styled.span`
  @media (min-width: ${breakpoint.medium}) {
    padding-left: 40px;
    padding-right: 40px;
    display: inline-block;
  }
`;
