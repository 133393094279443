import React, { useEffect } from 'react';
import SimplePageTemplate from '../../Layout/SimplePageTemplate/SimplePageTemplate';
import {
  ContentBody,
  ContentTitle,
  ContentTitleContainer,
} from '../../Layout/SimplePageTemplate/SimplePageTemplate.styled';
import { reloadSplashPage } from '../../../store/session';
import IconAlert from '@aib/uikit-react/dist/Icons/IconAlert';
import { color } from '@aib/design-tokens';

const IconWarning = (
  <IconAlert
    height={150}
    width={150}
    color={color.yellow70}
    strokeWidth={2.1}
  />
);

const LogoutOnMismatch = () => {
  useEffect(() => {
    sessionStorage.clear();
  }, []);

  return (
    <SimplePageTemplate
      onContinue={reloadSplashPage}
      isMobileApp={true}
      pageLogo={IconWarning}
      btnLabel={''}
      btnId={''}
    >
      <ContentTitleContainer>
        <ContentTitle>We could not authenticate you.</ContentTitle>

        <ContentBody>
          <p>You need to close this window and try again.</p>
          <p>
            Use the AIB Banking Registration Number and Personal Access Code
            (PAC) associated with this device.
          </p>
        </ContentBody>
      </ContentTitleContainer>
    </SimplePageTemplate>
  );
};

export default LogoutOnMismatch;
