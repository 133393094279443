import React from 'react';
import PropTypes from 'prop-types';
import Modal from '@aib/uikit-react/dist/Animations/Modal/Modal';
import InfoPopupView from '@aib/uikit-react/dist/Content/InfoPopupView/InfoPopupView';
import {
  ContactContainer,
  Paragraph,
  Title,
} from './AbsentPartyWarning.styled';

function AbsentPartyWarning({ showModal, onClick }) {
  const popupBody = (
    <>
      <Paragraph>Then you can manage your application here.</Paragraph>
      <Paragraph>
        Call us on <ContactContainer>0818 24 44 25</ContactContainer>, 9:00 to
        20:00 Monday to Friday.
      </Paragraph>
    </>
  );

  return (
    <Modal open={showModal}>
      <InfoPopupView
        title={
          <Title>
            Please make sure everyone has confirmed their details with the
            Mortgage Advisor.
          </Title>
        }
        body={[popupBody]}
        isWarning
        buttonText={'Got it'}
        onClick={onClick}
        hasCloseButton={false}
      />
    </Modal>
  );
}

AbsentPartyWarning.propTypes = {
  showModal: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default AbsentPartyWarning;
