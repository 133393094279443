import Utils from '../lib/utils';
const dataLayer = window.dataLayer || [];
const isMobileApp = Utils.isStringTrue(sessionStorage.getItem('isMobileApp'));

const pageView = (action) => ({
  hitType: 'pageview',
  pagePath: action.payload.pathname,
  pageUrl: window.location.href,
  isMobileApp,
});

const actionFailure = (category) => (action) => ({
  category,
  hitType: 'event',
  label: action.type,
  event: action.type,
  error: action.err,
  isMobileApp,
});

const actionWithNoId = (category) => (action) => ({
  category,
  hitType: 'event',
  label: action.type,
  event: action.type,
  isMobileApp,
});

const tagCustomEvent = (event) => (data) => {
  dataLayer.push({
    event,
    ...data,
    isMobileApp,
  });
};

/**
 * Custom events to add data points to the dataLayer used by google Tag Manager
 */

// check if client browser supports mediaDevices API
const mediaDevicesGoogleTag = () => {
  const supported = 'mediaDevices' in navigator;
  dataLayer.push({
    event: 'mediaDevices',
    mediaDeviceIsSupported: supported,
    hitType: 'event',
    category: 'Media Devices Support',
    isMobileApp,
  });
};

const trackClickViewForDocumentsTag = () => {
  dataLayer.push({
    event: 'viewDocument',
    hitType: 'event',
    category: 'View Document',
    isMobileApp,
  });
};

export {
  pageView,
  actionFailure,
  actionWithNoId,
  mediaDevicesGoogleTag,
  trackClickViewForDocumentsTag,
  tagCustomEvent,
};
