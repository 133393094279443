import React from 'react';
import PropTypes from 'prop-types';
import Utils from '../../../lib/utils';

class KeepAlive extends React.Component {
  constructor(props) {
    super(props);
    this.keepAliveFrequency = Utils.getSetting('KEEP_ALIVE_FREQUENCY');
  }

  componentDidMount() {
    this.keepAlive();
  }

  componentDidUpdate() {
    if (this.props.hasActiveSession)
      this.intervalId = setInterval(
        this.keepAlive,
        this.keepAliveFrequency * 1000
      );
    else clearInterval(this.intervalId);
  }

  keepAlive = () => {
    this.props.onKeepAlive({ autoRedirect: this.props.autoRedirect });
  };

  render() {
    return false;
  }
}

KeepAlive.propTypes = {
  onKeepAlive: PropTypes.func.isRequired,
  autoRedirect: PropTypes.bool,
  hasActiveSession: PropTypes.bool,
};

export default KeepAlive;
