import React, { useContext, useEffect } from 'react';
import SimplePageTemplate from '../../Layout/SimplePageTemplate/SimplePageTemplate';
import {
  ContentBody,
  ContentTitle,
  ContentTitleContainer,
} from '../../Layout/SimplePageTemplate/SimplePageTemplate.styled';
import AppContext from '../../../AppContext';
import cookieUtils from '../../../lib/cookieUtils';
import PropTypes from 'prop-types';
import { hideLoadingSpinner } from '../../../lib/spinnerUtils';

export const TYPES = {
  INACTIVITY: 'inactivity',
};

const LogoutLandingPage = ({ type }) => {
  useEffect(() => {
    hideLoadingSpinner();
    sessionStorage.clear();

    const targetType = process.env.REACT_APP_PROXY_TARGET_TYPE;
    if (targetType === 'JSON_SERVER') {
      cookieUtils.createMockCsrfCookies();
    }
  }, []);

  const handleContinue = () => {
    location.href = '/mortgages/';
  };
  const { isMobileApp } = useContext(AppContext);
  return (
    <SimplePageTemplate
      title={type === TYPES.INACTIVITY ? 'Timed out' : 'MyMortgage'}
      onContinue={handleContinue}
      btnLabel={'Log in to MyMortgage'}
      btnId={'logout-continue-btn'}
      isMobileApp={isMobileApp}
    >
      <ContentTitleContainer>
        <ContentTitle>We&apos;ve logged you out of MyMortgage.</ContentTitle>

        {isMobileApp && (
          <ContentBody>
            You need to close this window &#40;tap X or &apos;Done&apos;&#41;
            and reopen your session. You might have to log in again.
          </ContentBody>
        )}
      </ContentTitleContainer>
    </SimplePageTemplate>
  );
};

LogoutLandingPage.propTypes = {
  type: PropTypes.oneOf([TYPES.INACTIVITY]),
};

export default LogoutLandingPage;
