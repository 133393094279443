import styled, { css } from 'styled-components';
import { color, breakpoint, fontFamily, fontSize } from '@aib/design-tokens';
import { layoutPadding } from '../Theme/grid.styled';
import housesImg from '../Theme/assets/images/houses.svg';
import aibLogoImg from '../Theme/assets/images/aiblogo-flat.svg';
import Button from '@aib/uikit-react/dist/Form/Button/Button';
import ContinueButton from '@aib/mcx-shared-fe/dist/components/ContinueButton/ContinueButton';

export const Page = styled.div`
  height: 100%;

  @media (min-width: 576px) {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 420px;
    height: 680px;
    margin: auto;
  }

  @media (min-width: ${breakpoint.xLarge}) {
    width: 700px;
    border: solid 1px ${color.grey02};
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 0 0 0 rgba(0, 0, 0, 0.12),
      0 0 1px 0 rgba(0, 0, 0, 0.2);
  }
`;

export const PageHero = styled.div`
  width: ${(props) => props.width || '160px'};
  height: ${(props) => props.height || '160px'};
  background-size: contain;
  background-image: url(${(props) => props.src});
`;

export const PageBody = styled.div`
  ${layoutPadding};

  &&& {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    padding: 2rem;
  }

  @media (min-width: ${breakpoint.medium}) {
    background: ${color.baseWhite};
    position: relative;
  }

  @media (min-width: 576px) {
    border: 1px solid ${color.grey02};
    padding: 3rem;
    background: ${color.baseWhite};
    height: 100%;
    box-sizing: border-box;
  }
`;

export const PageMain = styled.main`
  font-family: ${fontFamily.regular};
  -webkit-font-smoothing: antialiased;

  ${(props) =>
    props.hasBackground &&
    css`
      background-image: linear-gradient(
          to bottom,
          rgba(255, 255, 255, 0.7) 0%,
          rgba(255, 255, 255, 0.7) 100%
        ),
        url(${housesImg});
    `}

  opacity: 1;
  will-change: opacity;
  background-size: cover;
  height: 100%;
  background-repeat: repeat-x;
  background-position: center bottom;

  @media (min-width: ${breakpoint.medium}) {
    background-size: cover;
    background-position: center bottom;
  }

  ${(props) =>
    props.isVisible === false &&
    css`
      opacity: 0;
    `}
`;

export const PageHead = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const PageTitle = styled.h1`
  margin-top: 1.6rem;
  margin-bottom: 0.2rem;
  color: ${color.corePurple100};
  font-size: 24px;
  font-family: ${fontFamily.medium};
  text-align: center;
`;

export const PageCtas = styled.div`
  width: 100%;
  max-width: 300px;
  text-align: center;
`;

export const PageLogo = styled.div`
  width: 80px;
  height: 80px;
  background-image: url(${aibLogoImg});
`;

export const PageHeroConfirm = styled.div`
  width: 160px;
  height: 160px;
  background-size: contain;
  background-image: url(${(props) => props.src});
`;

export const PageHeroLogIn = styled.div`
  width: ${(props) => props.width || '160px'};
  height: ${(props) => props.height || '160px'};
  background-size: contain;
  background-image: url(${(props) => props.src});
  margin-bottom: 0.5rem;
`;

export const ButtonStyled = styled(Button)`
  margin-bottom: 1.5rem;
  width: 100%;
`;

export const ContinueButtonStyled = styled(ContinueButton)`
  max-width: 360px;
  width: 100%;
  align-self: center;
`;

export const LogInRegisterPageFlex = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6rem;
`;

export const LogInChangedPageFlex = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3rem;

  @media (max-width: 375px) {
    gap: 1.5rem;
  }
`;

export const PageTopFlex = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const BodyParagraph = styled.div`
  color: ${color.grey07};
  font-family: ${fontFamily.regular};
  font-size: ${fontSize.medium};
  max-width: 314px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const CheckboxAndButtonContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const BodySubParagraph = styled.div`
  text-align: center;
  line-height: 22px;
  color: ${color.grey07};
  font-family: ${fontFamily.regular};
  font-size: ${fontSize.medium};
`;

export const CheckboxContainerStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${color.grey05};
`;

export const CheckboxFieldStyled = styled.div`
  display: flex;
  align-items: center;
`;

export const PageParagraph = styled.div`
  color: ${color.grey07};
  font-family: ${fontFamily.regular};
  font-size: ${fontSize.medium};
`;

export const PageSubParagraph = styled.div`
  margin-top: 1rem;
  text-align: center;
  color: ${color.grey07};
  font-family: ${fontFamily.regular};
  font-size: ${fontSize.medium};
`;

export const LandingPageContainer = styled.div`
  align-self: center;
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;
