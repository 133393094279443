import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import * as S from './SimplePageTemplate.styled';
import ContinueButton from '@aib/mcx-shared-fe/dist/components/ContinueButton/ContinueButton';
import aibLogoImg from './assets/aiblogo-flat.svg';
import { hideLoadingSpinner } from '../../../lib/spinnerUtils';

const SimplePageTemplate = ({
  title,
  onContinue,
  children,
  btnLabel,
  btnId,
  isMobileApp,
  pageLogo,
}) => {
  useEffect(hideLoadingSpinner, []);
  return (
    <S.Page>
      <S.PageBody>
        <S.PageHead>
          {pageLogo || <S.PageLogo src={aibLogoImg} />}
          {title && <S.PageTitle>{title}</S.PageTitle>}
        </S.PageHead>

        <S.ContentWrapper>{children}</S.ContentWrapper>
        {!isMobileApp && (
          <ContinueButton
            onClick={onContinue}
            label={btnLabel}
            testId={btnId}
            isEnabled={true}
            continueButtonId={btnId}
          />
        )}
      </S.PageBody>
    </S.Page>
  );
};

SimplePageTemplate.propTypes = {
  title: PropTypes.string,
  onContinue: PropTypes.func.isRequired,
  btnLabel: PropTypes.string.isRequired,
  btnId: PropTypes.string.isRequired,
  children: PropTypes.node,
  isMobileApp: PropTypes.bool,
  pageLogo: PropTypes.element,
};

export default SimplePageTemplate;
