import React, { useEffect } from 'react';
import * as S from '../../shared/Generic.styles';
import ContinueButton from '@aib/mcx-shared-fe/dist/components/ContinueButton/ContinueButton';
import confirmMobileImg from './confirm-mobile-image.svg';
import { navigateToProfilePage } from '../../../lib/urls';
import { hideLoadingSpinner } from '../../../lib/spinnerUtils';

const handleClick = () =>
  navigateToProfilePage(`${location.origin}/mortgages/ciam/profile/userinfo`);

const ConfirmMobile = () => {
  const lang = {
    HEADING_TITLE: 'Confirm your mobile number on the next screen.',
  };

  useEffect(hideLoadingSpinner, []);

  return (
    <S.Page>
      <S.PageBody>
        <S.PageHead>
          <S.PageHero src={confirmMobileImg} />
          <S.PageTitle>{lang.HEADING_TITLE}</S.PageTitle>
        </S.PageHead>

        <ContinueButton
          onClick={handleClick}
          label="Next"
          testId="manageProfileButton"
          isEnabled={true}
          continueButtonId={'manage-profile'}
        />
      </S.PageBody>
    </S.Page>
  );
};

export default ConfirmMobile;
