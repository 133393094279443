import React from 'react';
import PropTypes from 'prop-types';
import Modal from '@aib/uikit-react/dist/Animations/Modal/Modal';
import * as SS from './MyMortgageAccessModel.styled';
import GenericPopupView from '@aib/uikit-react/dist/Content/GenericPopupView/GenericPopupView';
import { connect } from 'react-redux';
import { getHasViewedAccessMessage } from '../../../store/persistentState/persistentState.selectors';
import { hideDialog } from '../../../store/dialog/dialog.actions';

function MyMortgageAccessModal({ isModalOpen, hideDialog }) {
  return (
    <Modal open={isModalOpen} canCloseWithEscapeKey={true} onClick={hideDialog}>
      <GenericPopupView
        title="MyMortgage Access"
        onClose={hideDialog}
        id="my-mortgage-access-modal"
      >
        <SS.Wrapper>
          <SS.ConnectIcon />
          <SS.StepContainer>
            <SS.TopParagraph>
              Each applicant can add documents for everyone, but must register
              or use their own login to do so. They&apos;ll need the mortgage
              reference number. It&apos;s on the next screen.
            </SS.TopParagraph>
          </SS.StepContainer>
          <SS.StepContainer>
            <SS.BoldSentence>
              Other applicants can get access by:
            </SS.BoldSentence>

            <SS.ListWrapper>
              <SS.ListItem>
                1. Opening &apos;mymortgage.aib.ie&apos;
              </SS.ListItem>
              <SS.ListItem>2. Selecting &apos;Get started&apos;</SS.ListItem>
              <SS.ListItem>
                3. Logging in if they are registered or registering
              </SS.ListItem>
              <SS.ListItem>
                4. Selecting &apos;Connect my application&apos;
              </SS.ListItem>
              <SS.ListItem>
                5. Entering the mortgage reference number
              </SS.ListItem>
            </SS.ListWrapper>
          </SS.StepContainer>
          <SS.GotItButton
            onClick={hideDialog}
            isEnabled={true}
            buttonStyle={'secondary'}
            label={'Got it'}
          ></SS.GotItButton>
        </SS.Wrapper>
      </GenericPopupView>
    </Modal>
  );
}

MyMortgageAccessModal.propTypes = {
  hideDialog: PropTypes.func,
  isModalOpen: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isModalOpen: !getHasViewedAccessMessage(state),
});

const mapDispatchToProps = {
  hideDialog,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MyMortgageAccessModal);
