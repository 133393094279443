import { path } from 'ramda';

export const getApplicationConnectFailed = (state) =>
  path(['ciamReducer', 'applicationConnectionFailed'], state);

export const getApplicationAbsentParty = (state) =>
  path(['ciamReducer', 'applicationConnectionAbsentParty'], state);

export const getLoggedInUserId = (state) =>
  path(['ciamReducer', 'profile', 'ciamId'], state);

export const getProfileData = (state) =>
  path(['ciamReducer', 'profile'], state);

export const isProfileFetched = (state) =>
  !path(['ciamReducer', 'isFetching'], state);

export const getRegNumber = (state) =>
  path(['ciamReducer', 'profile', 'regNumber'], state);
