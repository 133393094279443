import React from 'react';
import LogInOrRegister from '@aib/mcx-shared-fe/dist/pages/LogInOrRegisterPage/LogInOrRegister';
import PropTypes from 'prop-types';

const LogInOrRegisterPage = ({ navigateToNextPage }) => {
  return <LogInOrRegister handleClick={navigateToNextPage} />;
};

LogInOrRegisterPage.propTypes = {
  navigateToNextPage: PropTypes.func,
};

export default LogInOrRegisterPage;
