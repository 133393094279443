import * as types from './ciam.types';

export const connectApplication = (
  masApplicationId,
  oldAppId,
  mobileBankingFlag
) => ({
  type: types.CONNECT_APPLICATION,
  masApplicationId,
  oldAppId,
  mobileBankingFlag,
});

export const connectApplicationSuccess = () => ({
  type: types.CONNECT_APPLICATION_SUCCESS,
});

export const connectApplicationFailure = () => ({
  type: types.CONNECT_APPLICATION_FAILURE,
});

export const connectApplicationAbsentParty = () => ({
  type: types.CONNECT_APPLICATION_ABSENT_PARTY,
});

export const resetConnectApplicationError = () => ({
  type: types.RESET_CONNECT_APPLICATION_ERROR,
});

export const fetchProfileData = () => ({
  type: types.FETCH_PROFILE_DATA,
});

export const fetchProfileDataSuccess = (profile) => ({
  type: types.FETCH_PROFILE_DATA_SUCCESS,
  profile,
});

export const fetchProfileDataFailure = (profile) => ({
  type: types.FETCH_PROFILE_DATA_FAILURE,
  profile,
});
