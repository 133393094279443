import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  fetchApplicationData,
  setApplicationId,
  setCustomerType,
  setChannelDescription,
  fetchDashboardData,
} from '../api';
import { logoutOnMismatch, reloadSplashPage } from '../session';
import * as types from './applicationDetails.types';
import { fetchProfileDataFn } from '../ciam/ciam.sagas';

function* getApplicationDetails() {
  try {
    const response = yield call(fetchApplicationData);
    if (response.ok) {
      const customerDetails = response.resp.customerTypeDetails;
      const channelDetails = response.resp.channel;
      // Pass the applicationId, customerTypeDescriptiom and channelName to the api for use on (almost) every future request.
      setApplicationId(response.resp.applicationId);
      setCustomerType(customerDetails?.customerTypeDescription);
      setChannelDescription(channelDetails?.channelName);
      yield put({
        type: types.FETCH_APPLICATION_DETAILS_SUCCESS,
        data: response.resp,
      });
    } else {
      yield put({
        type: types.FETCH_APPLICATION_DETAILS_FAILURE,
        err: response.err,
      });
      reloadSplashPage();
    }
  } catch (e) {
    console.error('Error fetching applicationDetails', e);
    yield put({ type: types.FETCH_APPLICATION_DETAILS_FAILURE, err: e });
    reloadSplashPage();
  }
}

function* fetchExistingApplicationDetails({ data: { isMobileApp } }) {
  try {
    const [dashboardData, profileData] = yield all([
      call(fetchDashboardData),
      call(fetchProfileDataFn),
    ]);
    if (dashboardData.ok && profileData.ok) {
      if (isMobileApp) {
        const queryString = new URLSearchParams(document.location.search);
        const mobileAppRegNumber =
          queryString.get('login_hint') || sessionStorage.getItem('login_hint');
        sessionStorage.setItem('login_hint', mobileAppRegNumber);
        if (mobileAppRegNumber !== profileData.resp.regNumber) {
          yield put(logoutOnMismatch());
          return;
        }
      }
      yield put({
        type: types.FETCH_EXISTING_APPLICATION_DETAILS_SUCCESS,
        data: dashboardData.resp,
      });
    } else {
      yield put({
        type: types.FETCH_EXISTING_APPLICATION_DETAILS_FAILURE,
        err: dashboardData.err,
      });
    }
  } catch (e) {
    console.error('Error fetching applicationDetails', e);
    yield put({
      type: types.FETCH_EXISTING_APPLICATION_DETAILS_FAILURE,
      err: e,
    });
  }
}

// Side Effects/Saga
function* applicationDetailsSaga() {
  yield takeLatest(types.FETCH_APPLICATION_DETAILS, getApplicationDetails);
  yield takeLatest(
    types.FETCH_EXISTING_APPLICATION_DETAILS,
    fetchExistingApplicationDetails
  );
}

export {
  getApplicationDetails,
  applicationDetailsSaga,
  fetchExistingApplicationDetails,
};
