import { call, put, takeLatest } from 'redux-saga/effects';
import { refreshSession, logoutEndpoint } from './api';
import CookieUtils from '../lib/cookieUtils';
import {
  LOGOUT_LANDING,
  INACTIVITY_LANDING,
  LOGOUT_ON_MISMATCH,
} from '../lib/urls';

// Action Types
export const CONFIRM_LOGOUT_CLICK = 'sessionReducer/confirmLogoutClick';
export const REFRESH_SESSION = 'sessionReducer/refreshSession';
export const AUTO_LOGOUT = 'sessionReducer/autoLogout';
export const SESSION_IS_VALID = 'sessionReducer/sessionIsValid';
export const SESSION_IS_NOT_VALID = 'sessionReducer/sessionIsNotValid';
export const LOGOUT_ON_REGNUM_MISMATCH =
  'sessionReducer/logoutOnRegnumMismatch';

const createAction = (type) => (payload) => ({ type, ...payload });

// Actions
export const confirmLogoutClickAction = createAction(CONFIRM_LOGOUT_CLICK);
export const refreshSessionAction = createAction(REFRESH_SESSION);
export const autoLogoutAction = createAction(AUTO_LOGOUT);
export const sessionIsValid = createAction(SESSION_IS_VALID);
export const sessionIsNotValid = createAction(SESSION_IS_NOT_VALID);
export const logoutOnMismatch = createAction(LOGOUT_ON_REGNUM_MISMATCH);

export const reloadSplashPage = (logoutType) => {
  const qs = /true/i.test(sessionStorage.getItem('isMobileApp'))
    ? '?isMobileApp=true'
    : '';
  switch (logoutType) {
    case AUTO_LOGOUT:
      window.location = INACTIVITY_LANDING + qs;
      break;
    case LOGOUT_ON_REGNUM_MISMATCH:
      window.location = LOGOUT_ON_MISMATCH + qs;
      break;
    case CONFIRM_LOGOUT_CLICK:
    default:
      window.location = LOGOUT_LANDING + qs;
      break;
  }
};

const handleLogoutFailure = (logoutType) => {
  CookieUtils.deleteCsrfCookies();
  reloadSplashPage(logoutType);
};

// Worker Saga
export function* logoutWorker(logoutType) {
  try {
    const response = yield call(logoutEndpoint);
    if (response.ok) {
      yield put(sessionIsNotValid());
      reloadSplashPage(logoutType);
    } else {
      handleLogoutFailure(logoutType);
    }
  } catch (e) {
    console.error('Error logging out', e);
    handleLogoutFailure();
  }
}

export function* refreshSessionWorker(payload) {
  const response = yield call(refreshSession, payload?.autoRedirect);
  if (response.ok) yield put(sessionIsValid());
  else yield put(sessionIsNotValid());
}

// Side Effects/Saga
export function* logoutSaga() {
  yield takeLatest(CONFIRM_LOGOUT_CLICK, logoutWorker, CONFIRM_LOGOUT_CLICK);
  yield takeLatest(REFRESH_SESSION, refreshSessionWorker);
  yield takeLatest(AUTO_LOGOUT, logoutWorker, AUTO_LOGOUT);
  yield takeLatest(
    LOGOUT_ON_REGNUM_MISMATCH,
    logoutWorker,
    LOGOUT_ON_REGNUM_MISMATCH
  );
}
