import { call, put, takeEvery, all } from 'redux-saga/effects';
import * as types from './ciam.types';
import { getProfileData, postConnectApplication } from '../api';
import {
  connectApplicationAbsentParty,
  connectApplicationFailure,
  connectApplicationSuccess,
  fetchProfileDataFailure,
  fetchProfileDataSuccess,
} from './ciam.actions';
import { CONFIRM_MOBILE } from '../../lib/urls';
import ERROR_CODES from '../../lib/maps/apiErrorCodes.json';

export function* connectApplicationFn({
  masApplicationId,
  oldAppId,
  mobileBankingFlag,
}) {
  try {
    const response = yield call(
      postConnectApplication,
      masApplicationId,
      oldAppId,
      mobileBankingFlag
    );
    console.info('connect application', response);
    if (response.ok && response.resp !== false) {
      yield put(connectApplicationSuccess());
      window.location.assign('/mortgages/tasks');
      return;
    }

    if (response.resp.code === ERROR_CODES.ABSENT_PARTY) {
      yield put(connectApplicationAbsentParty());
    } else {
      yield put(connectApplicationFailure());
    }
  } catch (e) {
    yield put(connectApplicationFailure());
  }
}

export function* connectApplicationSaga() {
  yield takeEvery(types.CONNECT_APPLICATION, connectApplicationFn);
}

export function* fetchProfileDataFn() {
  try {
    const response = yield call(getProfileData);
    if (response.ok) {
      if (/false/i.test(String(response.resp.phoneNumberVerified)))
        location.href = CONFIRM_MOBILE;
      yield put(fetchProfileDataSuccess(response.resp));
    }
    return response;
  } catch (e) {
    yield put(fetchProfileDataFailure());
  }
}

export function* fetchProfileSaga() {
  yield takeEvery(types.FETCH_PROFILE_DATA, fetchProfileDataFn);
}

export default function* root() {
  yield all({
    connectApplicationSaga: call(connectApplicationSaga),
    fetchProfileSaga: call(fetchProfileSaga),
  });
}
