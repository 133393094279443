import * as messagesTypes from '../store/messages/messages.types';
import { actionWithNoId, actionFailure } from './eventDefinitions';
import utils from '../lib/utils';
const isMobileApp = utils.isStringTrue(sessionStorage.getItem('isMobileApp'));

const submitThreadReplySuccess = (category) => (action) => ({
  category,
  hitType: 'event',
  label: action.type,
  event: action.type,
  threadId: action.data.id,
  isMobileApp,
});

const actionWithDataThreadId = (category) => (action) => ({
  category,
  hitType: 'event',
  label: action.type,
  event: action.type,
  documentId: action.data.threadId,
  isMobileApp,
});

const messageEventsMap = {
  [messagesTypes.FETCH_THREADS_SUCCESS]: actionWithNoId('Messages'),
  [messagesTypes.FETCH_THREADS_FAILURE]: actionFailure('Messages'),
  [messagesTypes.FETCH_THREAD_SUCCESS]: actionWithDataThreadId('Messages'),
  [messagesTypes.FETCH_THREAD_FAILURE]: actionFailure('Messages'),
  [messagesTypes.MARK_THREAD_READ]: actionWithDataThreadId('Messages'),
  [messagesTypes.MARK_THREAD_READ_SUCCESS]: actionWithNoId('Messages'),
  [messagesTypes.MARK_THREAD_READ_FAILURE]: actionFailure('Messages'),
  [messagesTypes.SUBMIT_THREAD_REPLY]: actionWithDataThreadId('Messages'),
  [messagesTypes.SUBMIT_THREAD_REPLY_SUCCESS]:
    submitThreadReplySuccess('Messages'),
  [messagesTypes.SUBMIT_THREAD_REPLY_FAILURE]: actionFailure('Messages'),
  [messagesTypes.POST_THREAD]: actionWithNoId('Messages'),
  [messagesTypes.POST_THREAD_SUCCESS]: actionWithNoId('Messages'),
  [messagesTypes.POST_THREAD_FAILURE]: actionFailure('Messages'),
};

export default messageEventsMap;
