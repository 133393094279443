const origin = 'ciam';

const CONNECT_APPLICATION = `${origin}/connectApplication`;
const CONNECT_APPLICATION_SUCCESS = `${origin}/postSuccess`;
const CONNECT_APPLICATION_FAILURE = `${origin}/postFailure`;
const CONNECT_APPLICATION_ABSENT_PARTY = `${origin}/postAbsent`;
const RESET_CONNECT_APPLICATION_ERROR = `${origin}/resetError`;

const FETCH_PROFILE_DATA = `${origin}/fetchProfileData`;
const FETCH_PROFILE_DATA_SUCCESS = `${origin}/fetchProfileDataSuccess`;
const FETCH_PROFILE_DATA_FAILURE = `${origin}/fetchProfileDataFailure`;

export {
  CONNECT_APPLICATION,
  CONNECT_APPLICATION_SUCCESS,
  CONNECT_APPLICATION_FAILURE,
  CONNECT_APPLICATION_ABSENT_PARTY,
  RESET_CONNECT_APPLICATION_ERROR,
  FETCH_PROFILE_DATA,
  FETCH_PROFILE_DATA_SUCCESS,
  FETCH_PROFILE_DATA_FAILURE,
};
